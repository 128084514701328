import React, { useEffect, useState, useContext } from 'react';
import { Col, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { NotificationContext } from '../../components/notification/Notification';
import Authenticator from "../../services/Authenticator";

const WalletItem = ({ type, title, walletAddress }) => {
    const { notify } = useContext(NotificationContext);
    const [isRevealed, setIsRevealed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentWalletAddress, setCurrentWalletAddress] = useState(walletAddress);

    const handleReveal = () => {
        setIsRevealed(!isRevealed);
    };

    useEffect(() => {
        if (!walletAddress) {
            return;
        }
        
        setCurrentWalletAddress(walletAddress);
    }, [walletAddress]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleEditWalletAddress = (event) => {
        setCurrentWalletAddress(event.target.value)
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsRevealed(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const walletObj = type === 'ethereum'
            ? { ethereumWallet: currentWalletAddress }
            : { solanaWallet: currentWalletAddress };

        try {
            await Authenticator.updateAttributes({
                ...walletObj
            })
            notify({
                message: 'We have successfully updated your data!',
                type: 'success'
            });
            setIsEditing(false);
            setIsRevealed(false);
        } catch (error) {
            notify({
                message: 'Something went wrong. Please try again later.',
                type: 'critical'
            })
        }
    }

    const replaceMiddleWithAsterisk = (str) => {
        if (typeof str !== 'string') {
            return;
        }
        
        const len = str.length;
      
        if (len <= 2) {
          return '*'.repeat(len);
        }
      
        const start = 4;
        const end = len - start;
      
        return str.slice(0, start) + '*'.repeat(end - start) + str.slice(end);
      }
      

    return <Col xs={12} md={6} className={`walletWrapperCol mt-4 ${type}`}>
        <div className='walletWrapper d-flex flex-column justify-content-between'>
            <div>
                <p>{title}</p>
                {!isEditing && <p className={`walletAddress ${isRevealed ? 'revealed' : ''}`}>{
                    isRevealed ? currentWalletAddress : replaceMiddleWithAsterisk(currentWalletAddress)
                }</p>}
                {isEditing && <textarea
                    value={currentWalletAddress}
                    onChange={handleEditWalletAddress}
                />}
            </div>

            <div className='d-flex flex-row align-items-center justify-content-between'>
                {!isEditing && <Link to='' onClick={handleReveal} className='reveal d-block'>{isRevealed ? 'Hide' : 'Reveal'}</Link>}
                {isEditing && <Link to='' onClick={handleCancel} className='reveal d-block'>Cancel</Link>}
                {!isEditing && <Button onClick={handleEdit} className='editButton'>Edit</Button>}
                {isEditing && <Button onClick={(event) => {(async() => await handleSubmit(event))()}} className='editButton'>Save</Button>}
            </div>
        </div>
    </Col>
};

export default WalletItem;